import axios from '@/config/axios';
import { klantIndexById } from '@/utils/filterFunctions';

export default {
    state: {
        klanten: null,
        voorstellen: null,
        preblacklist: null,
        klantenGeladen: false,
        collegas: [],
        collegasGeladen: false,
    },
    mutations: {
        SET_KLANTEN(state, klanten) {
            state.klanten = klanten;
            state.klantenGeladen = true;
        },
        UPDATE_KLANT(state, klant) {
            const { id } = klant;
            const index = klantIndexById(id, state.klanten);
            for (const [key, value] of Object.entries(klant)) {
                state.klanten[index][key] = value;
            }
        },
        SET_COLLEGAS(state, collegas) {
            state.collegas = collegas.sort((a, b) => a.name > b.name ? 1 : -1);
            state.collegasGeladen = true;
        },
    },
    actions: {
        async getKlanten({ commit }) {
            try {
                const klanten = await axios.get('/zoho/klanten');
                commit('SET_KLANTEN', klanten.data);
                return true;
            } catch (err) {
                console.error(err);
            }
        },
        updateKlant({ commit }, klant) {
            commit('UPDATE_KLANT', klant);
        },
        async getCollegas({ commit }) {
            try {
                const collegas = await axios.get('/users/accounts');
                commit('SET_COLLEGAS', collegas.data);
                return true;
            } catch (err) {
                console.error(err);
            }
        },
    },
    getters: {
        klanten(state) {
            return state.klanten;
        },
        countStatus(state) {
            return state.klanten === null
                ? { geen_klanten: 0 }
                : state.klanten
                    .filter((e) => e.Status_inschrijving === 'Ingeschreven')
                    .reduce((a, c) => {
                        if (!a[c.Status_bemiddeling]) a[c.Status_bemiddeling] = 0;
                        a[c.Status_bemiddeling]++;
                        return a;
                    }, {});
        },
        klantenGeladen(state) {
            return state.klantenGeladen;
        },
        actiefBemiddelbareKlanten(state) {
            return state.klanten
                .filter((e) => e.Status_bemiddeling === '4.0 Beschikbaar' || e.Status_bemiddeling === '4.1 Prioriteit')
                .map((e) => e.id);
        },
        klantNameById: (state) => (id) => {
            return state.klanten.filter((e) => e.id === id)[0].Account_Name;
        },
        collegas(state) {
            return state.collegas;
        },
    },
};
